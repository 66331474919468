<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm em Portas</h2>

            <p>
              O uso de insulfilm em portas de vidro é muito comum em
              estabelecimentos comerciais ou residências com amplos quintais e
              áreas de lazer onde as tardes podem ser aproveitadas ao ar livre.
            </p>

            <p>
              Além de deixar o ambiente mais moderno, também proporciona uma
              visão mais ampla do mundo exterior. De certa forma, no entanto, é
              um pouco desconfortável pensar que as pessoas de fora podem ver o
              que está acontecendo neste lugar. Este problema pode ser resolvido
              colocando insulfilm nas <strong>portas de vidro.</strong>
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>
            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[1].thumb"
                :title="photos[1].title"
                :description="photos[1].description"
                :idLocation="photos[1].idLocation"
                :openLightBox="false"
              />
            </div>
            <p>
              Um estabelecimento como um negócio está sempre na recepção de
              pessoas e muitas vezes lida com dinheiro e mercadorias, portanto,
              manter as <strong>instalações seguras e privadas</strong> é
              fundamental para o que está acontecendo lá dentro, mas o processo
              não pode impedir que o mundo exterior veja o que está acontecendo,
              portanto, é ideal colocar um película de insulfilme nas portas de
              vidro.
            </p>


            <p>
              Assim como nos negócios, os residenciais carecem da mesma coisa. É
              ótimo ter vista para o jardim ou para qualquer cômodo da casa, por
              isso cada vez mais são utilizados projetos que utilizam um campo
              de visão maior, mas não interferem na privacidade do interior da
              casa, que também pode ser resolvido colocando uma película de
              insulfilme nas portas.
            </p>

            <h3>Aplicação de insulfilmes para guaritas e portarias</h3>

            <p>
              Para aplicar insulfilm em portas de vidro, é importante conhecer e
              contratar uma empresa profissional como a
              <router-link :to="{ name: 'about' }">MF Film</router-link>, que
              tem mais de 18 anos de experiência no ramo e está constantemente
              aprimorando seus recursos e serviços para oferecer os melhores
              materiais e resultados. Mantenha seus clientes satisfeitos.
            </p>

            <p>
              <router-link :to="{ name: 'quotation' }"
                >Faça uma cotação</router-link
              >, fale com um especialista e veja como as películas podem ajudar
              na privacidade, seguranca e decoração.
            </p>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");

export default {
  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
  },

  data() {
    return {
      layoutLoad: false,
      index: null,
      photos: [        
        {
          description:
            "Aplicação da película prata refletivo para privatizar o ambiente e diminuir o calor",
          idLocation: 6,
          src: "/images/fotos/cont-soft/pelicula-insulfilm-prata-refletivo-cont-soft-5",
          thumb: "/images/fotos/cont-soft/small/pelicula-insulfilm-prata-refletivo-cont-soft-5",
          title: "Vista externa",
        },
        {
          description:
            "Aplicação da película prata refletivo para privatizar o ambiente e diminuir o calor",
          idLocation: 6,
          src: "/images/fotos/cont-soft/pelicula-insulfilm-prata-refletivo-cont-soft-3",
          thumb: "/images/fotos/cont-soft/small/pelicula-insulfilm-prata-refletivo-cont-soft-3",
          title: "Vista interna",
        },
        
      ],
    };
  },

  mounted: function () {
    //console.log(this.photos)
    // console.log('mounted')
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm para Portas");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm nas portas para privacidade, segurança e decoração"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>